import { convertDateFormat } from '../../helpers/date-time';
import { ActivityType, TimeslotType, BookingType } from '../../utils/enums';

export const useTimeslotHandler = (
  timeBegin: string,
  timeEnd: string,
  activityType: string,
  bookingStatus: string
) => {
  const activityStarts = convertDateFormat(timeBegin).time;
  const activityFinishs = convertDateFormat(timeEnd).time;

  let eventStatus: TimeslotType;
  if (activityType === ActivityType.TourOrActivity) {
    if (
      bookingStatus === BookingType.Waitlist ||
      bookingStatus === BookingType.WaitListAllTimeslots
    ) {
      eventStatus = TimeslotType.TimeslotWaitlist;
    } else {
      eventStatus = TimeslotType.TimeslotBooking;
    }
  } else if (activityType === ActivityType.AgendaItem) {
    eventStatus = TimeslotType.TimeslotEvent;
  } else {
    eventStatus = TimeslotType.TimeslotEvent;
  }
  return { activityStarts, activityFinishs, eventStatus };
};
