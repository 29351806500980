import { Text, StyleSheet } from 'react-native';
import { TextProps } from '../../../utils/types';
import { fontSize, fontFamilyConst, colourConst } from '../../../globalStyles';
import React from 'react';

const TextLogin = ({ children }: TextProps) => {
  return <Text style={style.text}>{children}</Text>;
};
const style = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.large,
    color: colourConst.white,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
});
export default TextLogin;
