import { useSecureContext } from '../context';
import Constants from 'expo-constants';
import getBaseUrl from '../helpers/getBaseUrl';

export const useLogout = () => {
  const { AUTH0_CLIENT_ID, AUTH0_ISSUER_BASE_URL } =
    Constants.expoConfig!.extra!;
  const urlString = window.location.href;
  const baseUrl = getBaseUrl(urlString);
  const logoutUrl = `${AUTH0_ISSUER_BASE_URL}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${baseUrl}/login`;
  const { setState } = useSecureContext();

  const handleLogout = async () => {
    await setState(() => ({
      token: null,
      state: null,
      initialized: false,
      locationAndWave: null,
      contextTokens: null,
      userData: null,
    }));
    localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
    window.location.href = logoutUrl;
  };

  return {
    handleLogout,
  };
};
