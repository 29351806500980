import { useEffect, useState } from 'react';
import { DigitalAssetType } from '../utils/enums';
import { useSecureContext } from '../context';
import placeholder from '../../assets/GWC23.png';
import homePlaceholder from '../../assets/gwc24/GWC24 DEA Generic branding image.jpg';
import Constants from 'expo-constants';

const useGetAssets = (imageId: string, isHomepage = true) => {
  const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;
  const { token } = useSecureContext();
  const [image, setImage] = useState('');

  useEffect(() => {
    if (imageId) {
      fetch(
        `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/${DigitalAssetType.MobileGeneral}/${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setImage(data.Url);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setImage(placeholder);
    }
  }, [NEXT_PUBLIC_ASSETS_BASE_URL, imageId, token]);

  const imageToDisplay =
    imageId && image
      ? { uri: image }
      : isHomepage
      ? homePlaceholder
      : placeholder;
  const imageWithNoPlaceholder = imageId && image ? { uri: image } : undefined;

  return { imageToDisplay, setImage, imageWithNoPlaceholder, homePlaceholder };
};

export default useGetAssets;
