import React, { ComponentType } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { parseISO, format } from 'date-fns';
import { ProjectRoles } from './types';

export const withSafeArea = <P extends Record<string, any>>(
  component: ComponentType<P>
) => {
  const Comp = component;

  const WithSafeArea = (props: P) => {
    return (
      <SafeAreaView style={style.container}>
        <Comp {...props} />
      </SafeAreaView>
    );
  };
  const style = StyleSheet.create({
    container: {
      flex: 1,
    },
  });

  return WithSafeArea;
};

export const formatTime = (timeString: string) => {
  const ISOtime = parseISO(timeString);

  const formattedTime = format(ISOtime, 'p');

  return formattedTime;
};

export function checkGoldenStatus(
  projectRoles: ProjectRoles[] | undefined
): boolean {
  return projectRoles?.find((role) => role.Reference === 'GCA') !== undefined;
}
