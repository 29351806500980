import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { fontFamilyConst, colourConst, fontSize } from '../../globalStyles';
import { useHomeDropdown } from './useHomeDropdown';
import { UserProjectInterface } from '../../types';

const HomeDropdown = () => {
  const {
    open,
    setState,
    locationsAndWaves,
    locationAndWave,
    currentLocationWaves,
    onPress,
  } = useHomeDropdown();
  console.log('locationsAndWaves', locationsAndWaves);
  console.log('currentLocationWaves', currentLocationWaves);
  return (
    <View style={styles.dropdownWrapper}>
      <Text onPress={onPress} style={styles.headerTitle}>
        {locationAndWave ? `My Winners Circle` : 'Location | Date - Date'}
        <View style={[styles.triangle, open && styles.triangleRotated]} />
      </Text>
      {open && (
        <View style={styles.dropdown}>
          {/*  */}
          <View style={styles.labelMargin}>
            <Text style={styles.label}>Location</Text>
          </View>
          {locationsAndWaves?.map(({ waves, ...location }) => (
            <Pressable
              style={[
                styles.option,
                location.ProjectId === locationAndWave?.ProjectId &&
                  styles.optionSelected,
              ]}
              key={location.ProjectId}
              onPress={() => {
                const sortedWaves = [...waves]?.sort((a, b) =>
                  a.Name.localeCompare(b.Name)
                );
                setState((prev) => {
                  if (!prev) {
                    return prev;
                  }

                  return {
                    ...prev,
                    locationAndWave: {
                      ...location,
                      wave: sortedWaves?.[0] ?? null,
                    },
                  };
                });
                onPress();
              }}
            >
              <Text
                style={[
                  styles.optionText,
                  location.ProjectId === locationAndWave?.ProjectId &&
                    styles.optionSelectedText,
                ]}
              >
                {location.Name}
              </Text>
            </Pressable>
          ))}
          <View style={styles.labelMargin}>
            <Text style={styles.label}>Date</Text>
          </View>
          {currentLocationWaves
            ?.sort((a, b) => a.Name.localeCompare(b.Name))
            .map((wave) => (
              <Pressable
                key={wave.ProjectId}
                style={[
                  styles.option,
                  wave.ProjectId === locationAndWave?.wave?.ProjectId &&
                    styles.optionSelected,
                ]}
                onPress={() => {
                  setState((prev) => {
                    if (!prev || !prev.locationAndWave) {
                      return prev;
                    }

                    return {
                      ...prev,
                      locationAndWave: {
                        ...prev.locationAndWave,
                        wave,
                      },
                    };
                  });
                  onPress();
                }}
              >
                <Text
                  style={[
                    styles.optionText,
                    wave.ProjectId === locationAndWave?.wave?.ProjectId &&
                      styles.optionSelectedText,
                  ]}
                >
                  {wave.DateRangeDisplayLong}
                </Text>
              </Pressable>
            ))}
        </View>
      )}
    </View>
  );
};

export default HomeDropdown;
const styles = StyleSheet.create({
  dropdownWrapper: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.large,
    color: colourConst.blue,
  },
  dropdown: {
    position: 'absolute',
    top: 30,
    width: 200,
    height: 'auto',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'grey',
    padding: 10,
    paddingBottom: 0,
    borderRadius: 10,
  },
  triangle: {
    marginLeft: 10,
    width: 8,
    height: 8,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderColor: 'grey',
    transform: [{ translateY: -4 }, { rotate: `${180 - 45}deg` }],
    transition: 'ease-in-out 0.2s transform',
  },
  triangleRotated: {
    transform: [{ translateY: 0 }, { rotate: '-45deg' }],
  },
  labelMargin: {
    marginBottom: 10,
  },
  label: {
    fontFamily: fontFamilyConst.bold,
    textAlign: 'center',
    color: colourConst.blue,
  },
  option: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'grey',
    marginBottom: 10,
    fontFamily: fontFamilyConst.regular,
  },
  optionSelected: {
    backgroundColor: colourConst.blue,
    borderColor: 'transparent',
    color: 'salmon',
    fontFamily: fontFamilyConst.regular,
  },
  optionText: {
    fontFamily: fontFamilyConst.regular,
  },
  optionSelectedText: {
    color: 'white',
    fontFamily: fontFamilyConst.regular,
  },
});
