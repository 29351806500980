import { useState } from 'react';
import validator from 'validator';
import { colourConst } from '../../globalStyles';
import Constants from 'expo-constants';
import {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  withDelay,
  interpolateColor,
} from 'react-native-reanimated';

const { AUTH0_ISSUER_BASE_URL, AUTH0_CLIENT_ID, AUTH0_ORGANIZATION } =
  Constants.expoConfig!.extra!;

const usePasswordRequestForm = () => {
  const warningColourAnimated = useSharedValue(0);
  const warningOpacityAnimated = useSharedValue(0);
  const warningAnimatedStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        warningColourAnimated.value,
        [0, 1],
        [colourConst.red, colourConst.white]
      ),
      opacity: withTiming(warningOpacityAnimated.value, {
        duration: 300,
        easing: Easing.linear,
      }),
    };
  });

  const confirmationOpacityAnimated = useSharedValue(0);
  const confirmationAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(confirmationOpacityAnimated.value, {
        duration: 300,
        easing: Easing.linear,
      }),
    };
  });

  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async () => {
    if (validator.isEmail(email)) {
      setIsInvalidEmail(false);
      warningOpacityAnimated.value = 0;
      const data = {
        client_id: AUTH0_CLIENT_ID,
        email,
        connection: 'Username-Password-Authentication',
        organization: AUTH0_ORGANIZATION,
      };

      try {
        const response = await fetch(
          `${AUTH0_ISSUER_BASE_URL}/dbconnections/change_password`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setIsEmailSent(true);
        confirmationOpacityAnimated.value = 1;
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.log('email is not valid');
      setIsInvalidEmail(true);
      confirmationOpacityAnimated.value = 0;
      warningOpacityAnimated.value = 1;
      warningColourAnimated.value = 0; // reset the animation
      warningColourAnimated.value = withDelay(
        500,
        withTiming(1, {
          duration: 600,
          easing: Easing.linear,
        })
      );
    }
  };

  return {
    email,
    setEmail,
    isInvalidEmail,
    handleSubmit,
    warningAnimatedStyle,
    warningAnimated: warningColourAnimated,
    confirmationAnimatedStyle,
    isEmailSent,
  };
};

export default usePasswordRequestForm;
