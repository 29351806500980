export const colourConst = {
  blue: '#035CA7',
  green: '#158D2B',
  ambar: '#dcc609',
  event: '#FCC602',
  inactiveGray: '#AFAFAF',
  guesButtonLogin: 'rgba(151, 151, 151, 0.95)',
  white: '#FFFFFF',
  font: '#575756',
  fontOpacity: 'rgba(87, 87, 86, 0.2)',
  transparent: 'rgba(0, 0, 0, 0)',
  background: '#EBF6FF',
  whiteTransparent: 'rgba(255, 255, 255, 0.8)',
  unselected: '#58595B',
  unselectedTabDayText: 'rgba(87, 87, 86, 0.5)',
  dateTabUnselected: 'rgba(241, 241, 241, 0.7)',
  // waitList: '#F2D904',
  waitList: '#FFAF00',
  red: '#FF0000',
};

export const imgIconSize = {
  width: 30,
  height: 30,
};

export const fontSize = {
  extraSmall: 9,
  small: 10,
  smallRegular: 11,
  regular: 12,
  smallMedium: 14,
  medium: 15,
  mediumLarge: 18,
  large: 20,
  extraLarge: 24,
  xxL: 30,
};

export const fontFamilyConst = {
  regular: 'coreSansRegular',
  medium: 'coreSansMedium',
  bold: 'coreSansBold',
  extraBold: 'coreSansExtraBold',
};
export const layoutAgenda = {
  radius: 10,
  radiusTab: 5,
  margin: 0,
  marginBottom: 15,
  gap: 2,
  paddingTabs: 10,
};
export const timeslots = {
  margin: 16,
  margingTop: 10,
  marginBottom: 0,
  radius: 2,
  padding: 10,
  paddingBottom: 15,
  timeMargin: 10,
  textMargin: 0,
  textLeftMargin: 15,
  lineHeight: 20,
  imageWidth: 60,
  imageHeight: 60,
  imageMarginLeft: 7,
  imageBorderRadius: 50,
  gap: 10,
};
export const homeLayout = {
  maxWidth: 500,
};
export const shadowConst = {
  radius: 5,
  opacity: 0.25,
  elevation: 10,
  width: 0,
  height: 5,
};
