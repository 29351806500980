import Constants from 'expo-constants';
import { PhotosCategory } from '../types';

const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;

export const getPhotoCategories = (token: string, waveId: string) => {
  const photoCategoriesUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/media-categories/2/with-count-v2/${waveId}?mediaStatusTypeIdsCsv=1`;
  return fetch(photoCategoriesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<PhotosCategory[]>;
    }

    throw response;
  });
};
export const getPhotoIds = (
  token: string,
  categoryId: string,
  waveId: string
) => {
  const photosUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/medias/2/filter?typeId=7&projectId=${waveId}&categoryId=${categoryId}&mediaStatusTypeIdsCsv=1`;
  return fetch(photosUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<PhotosCategory[]>;
    }

    throw response;
  });
};

export async function getImageUrls(token, array, callback) {
  for (const element of array) {
    const imageData = await fetchImageUrl(token, element.Id);
    const imageUrl = {
      src: imageData,
      width: element.IsPortrait ? 2 : 3,
      height: element.IsPortrait ? 3 : 2,
    };
    callback(imageUrl);
  }
}

export async function fetchImageUrl(token, id) {
  try {
    const response = await fetch(
      `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/2/${id}?digitalAssetUse=1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
    }

    const result = await response.json();

    if (!result || !result.Url) {
      throw new Error('Unexpected response format or missing URL');
    }

    return result.Url;
  } catch (error) {
    console.error('Error fetching image URL:', error);
    throw error;
  }
}
