import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import {
  layoutAgenda,
  colourConst,
  fontFamilyConst,
  fontSize,
} from '../../globalStyles';
import Animated from 'react-native-reanimated';
import ButtonSubmit from '../ButtonSubmit';
import usePasswordRequestForm from './usePasswordRequestForm';

interface PasswordRequestFormProps {
  handleIsRequested: () => void;
}

const PasswordRequestForm = ({
  handleIsRequested,
}: PasswordRequestFormProps) => {
  const {
    email,
    setEmail,
    isInvalidEmail,
    handleSubmit,
    warningAnimatedStyle,
    confirmationAnimatedStyle,
    isEmailSent,
  } = usePasswordRequestForm();

  return (
    <View style={styles.container}>
      <Animated.Text
        style={[
          styles.warning,
          isInvalidEmail ? styles.warningVisible : styles.warningNoVisible,
          warningAnimatedStyle,
        ]}
      >
        Please, enter a valid email.
      </Animated.Text>
      {!isEmailSent ? (
        <TextInput
          style={styles.input}
          autoCapitalize={'none'}
          keyboardType={'email-address'}
          placeholder={' example@sample.com'}
          placeholderTextColor={'grey'}
          onChangeText={(text) => setEmail(text)}
          value={email}
        />
      ) : (
        <Animated.Text
          style={[styles.confirmation, confirmationAnimatedStyle]}
        >{`An email has been sent to ${email}`}</Animated.Text>
      )}
      <View style={styles.buttonsWrapper}>
        <ButtonSubmit
          onPress={handleIsRequested}
          text={isEmailSent ? 'LOGIN' : 'BACK'}
          myStyle={{
            color: colourConst.blue,
            backgroundColor: colourConst.white,
            borderWidth: 1,
            borderColor: colourConst.blue,
          }}
        />
        {isEmailSent ? null : (
          <ButtonSubmit
            onPress={handleSubmit}
            text={'SUBMIT'}
            myStyle={{
              color: colourConst.white,
              backgroundColor: colourConst.green,
              borderWidth: 0,
            }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  input: {
    borderRadius: layoutAgenda.radiusTab,
    borderColor: colourConst.white,
    borderWidth: 1,
    width: '80%',
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
    paddingHorizontal: '2%',
    paddingVertical: '3%',
    marginBottom: '10%',
    backgroundColor: colourConst.white,
  },
  warning: {
    marginBottom: '1%',
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.smallMedium,
    marginTop: '3%',
    textShadowColor: '#000000B2',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 4,
  },
  warningNoVisible: {
    opacity: 0,
  },
  warningVisible: {
    opacity: 1,
  },
  confirmation: {
    fontFamily: fontFamilyConst.regular,
    color: colourConst.white,
    marginTop: '1%',
    fontSize: fontSize.smallMedium,
    marginBottom: '10%',
    textShadowColor: '#000000B2',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 4,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '5%',
    width: '40%',
    maxWidth: '70%',
  },
});

export default PasswordRequestForm;
