import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';

interface ButtonSubmitProps {
  onPress?: () => void;
  text: string;
  myStyle: {
    color: string;
    backgroundColor: string;
    borderWidth: number;
    borderColor?: string;
  };
}

const ButtonSubmit = ({
  onPress: submit,
  text,
  myStyle,
}: ButtonSubmitProps) => {
  return (
    <Pressable
      style={[
        styles.container,
        {
          backgroundColor: myStyle.backgroundColor,
          borderWidth: myStyle.borderWidth,
          borderColor: myStyle.borderColor,
        },
      ]}
      onPress={submit}
    >
      <Text style={[styles.text, { color: myStyle.color }]}>{text}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colourConst.blue,
    borderRadius: 8,
    padding: 12,
  },
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.medium,
    textAlign: 'center',
  },
});
export default ButtonSubmit;
