import React from 'react';
import { Text, StyleSheet, useWindowDimensions } from 'react-native';
import { TextProps } from '../../../utils/types';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withDelay,
  Easing,
} from 'react-native-reanimated';
import { fontFamilyConst, colourConst, fontSize } from '../../../globalStyles';
import useTextAnimation from '../../../hooks/useTextAnimation';

const TextLoginSubTitle = ({ children }: TextProps) => {
  const { textOpacity, animatedTextStyle } = useTextAnimation();
  const windowDimensions = useWindowDimensions();
  const fontSize =
    windowDimensions.width <= 320 || windowDimensions.height <= 800 ? 10 : 13;

  return (
    <Animated.Text
      style={[style.text, animatedTextStyle, { fontSize: fontSize }]}
      onLayout={() => {
        textOpacity.value = withDelay(
          1500,
          withTiming(1, {
            duration: 500,
            easing: Easing.linear,
          })
        );
      }}
    >
      {children}
    </Animated.Text>
  );
};
const style = StyleSheet.create({
  text: {
    textShadowColor: '#000000B2',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 4,
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallMedium,
    color: colourConst.white,
    textAlign: 'center',
    textAlignVertical: 'center',
    marginTop: '5%',
    marginHorizontal: '10%',
  },
});
export default TextLoginSubTitle;
