import { useEffect, useState, useContext } from 'react';
import { convertDateFormat } from '../../helpers/date-time';
import {
  WeatherDataProps,
  WeatherDataCity,
  WeatherDataList,
} from '../../utils/types';
import useGetTokens from '../../hooks/useGetTokens';
import { SettingsContext } from '../../../App';
import { set } from 'date-fns';

export const useWeather = (location?: string) => {
  const { isFahrenheit } = useContext(SettingsContext);
  console.log('location :>> ', location);
  const weatherToken = useGetTokens()?.WeatherApiAccessKey;
  let cityCounty = ' ';
  let city = '';
  // console.log(weatherToken);
  switch (location?.toLocaleLowerCase()) {
    case 'rome':
      cityCounty = 'Rome, Italy';
      city = 'rome,it';
      break;
    case 'austin':
      cityCounty = 'Austin, Texas';
      city = 'austin,us';
      break;
    case 'maui':
      cityCounty = 'Maui, Hawaii';
      city = 'kahului,us';
      break;
    case 'lisbon':
      cityCounty = 'Lisbon, Portugal';
      city = 'lisbon,pt';
      break;
    case 'big island':
      cityCounty = 'Big Island, Hawaii';
      city = 'puako,us';
      break;
    case 'lisbon - corinthia':
      cityCounty = 'Lisbon, Portugal';
      city = 'lisbon,pt';
      break;
    case 'lisbon - epic sana':
      cityCounty = 'Lisbon, Portugal';
      city = 'lisbon,pt';
      break;
    case 'riviera maya':
      cityCounty = 'Riviera Maya, Mexico';
      city = 'cancun,mx';
      break;
    case 'carlsbad':
      cityCounty = 'Carlsbad, California';
      city = 'carlsbad,ca,us';
      break;
    case 'monte carlo':
      cityCounty = 'Monte Carlo, Monaco';
      city = 'monte carlo,mc';
      break;
    case 'prague':
      cityCounty = 'Prague, Czech Republic';
      city = 'prague,cz';
      break;
    case 'san diego':
      cityCounty = 'San Diego, California';
      city = 'san diego,ca,us';
      break;
    case 'singapore':
      cityCounty = 'Singapore';
      city = 'singapore,sg';
      break;
  }

  const [isLoading, setIsLoading] = useState(true);
  const [weatherData, setWeatherData] = useState<WeatherDataProps | null>(null);
  const [weatherDataList, setWeatherDataList] = useState<
    WeatherDataList[] | []
  >([]);
  const [weatherDataCity, setWeatherDataCity] = useState<WeatherDataCity | []>(
    []
  );

  useEffect(() => {
    const getWeatherData = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?q=${city}&units=imperial&cnt=8&appid=${weatherToken}`
        );
        const data = await response.json();
        setWeatherDataCity(data.city);
        setWeatherData(data);
        setWeatherDataList(data.list);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    if (weatherToken) {
      getWeatherData();
    }
  }, [city, weatherToken]);
  const localTimeConverter = (unixTime: number, timezoneInSeconds: number) => {
    const localTime = new Date((unixTime + timezoneInSeconds) * 1000);
    const hour = convertDateFormat(localTime).time;
    const weekDay = convertDateFormat(localTime).dayOfWeek;
    const month = convertDateFormat(localTime).month;
    const date = convertDateFormat(localTime).day;

    return { localTime, hour, weekDay, month, date };
  };
  const getDateToDisplay = () => {
    const dt = weatherData?.list?.[0]?.dt;
    const timezone = weatherData?.city?.timezone;
    if (dt && timezone) {
      const dateDisplay = localTimeConverter(dt, timezone);
      return `${dateDisplay.weekDay}, ${dateDisplay.month} ${dateDisplay.date}`;
    } else {
      return '';
    }
  };
  const timeForecast = (dtItem: number) => {
    console.log('weatherData', weatherData);
    const timezone = weatherData?.city?.timezone;
    if (dtItem && timezone) {
      return localTimeConverter(dtItem, timezone).hour;
    } else {
      return '';
    }
  };
  const getTemperature = (temp: number) => {
    if (isFahrenheit) {
      return `${temp.toPrecision(2)} °F`;
    } else {
      return `${((temp - 32) / 1.8).toPrecision(2)} °C`;
    }
  };

  return {
    cityCounty,
    weatherData,
    weatherDataCity,
    weatherDataList,
    localTimeConverter,
    getDateToDisplay,
    timeForecast,
    getTemperature,
    isLoading,
  };
};
