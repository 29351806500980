import React from 'react';
import { View, StyleSheet } from 'react-native';
import TextTimeEvent from '../TextComponents/TextTimeEvent';
import TextActEvent from '../TextComponents/TextActEvent';
import { EventProps } from '../../utils/types';
import { colourConst, timeslots } from '../../globalStyles';
import Animated from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { useAgendaEvent } from './utils';
import TourInfo from '../TourInfo';
import { Image } from 'expo-image';

const AgendaEvent = ({
  timeBegin,
  timeEnd,
  activityName,
  activeTab,
  agendaViewDetail,
  imageUrl,
}: EventProps) => {
  const {
    animatedStyles,
    containerStyle,
    singleTap,
    imageToDisplay,
    onLayoutEffect,
  } = useAgendaEvent(activeTab, imageUrl, false);
  return (
    <>
      <GestureDetector gesture={Gesture.Exclusive(singleTap)}>
        <Animated.View
          style={[styles.container, containerStyle]}
          onLayout={onLayoutEffect}
        >
          <Image
            source={imageToDisplay}
            style={styles.image}
            contentFit="cover"
            transition={{
              duration: 300,
              timing: 'linear',
              effect: 'cross-dissolve',
            }}
          />
          <View style={styles.textContainer}>
            <TextTimeEvent
              isBooked={false}
            >{`${timeBegin} - ${timeEnd}`}</TextTimeEvent>
            <TextActEvent isBooked={false}>{activityName}</TextActEvent>
          </View>
        </Animated.View>
      </GestureDetector>
      <Animated.ScrollView style={[styles.animated, animatedStyles]}>
        <TourInfo activityViewDetail={agendaViewDetail} />
      </Animated.ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    width: timeslots.imageWidth,
    height: timeslots.imageHeight,
    marginLeft: timeslots.imageMarginLeft,
    borderRadius: timeslots.imageBorderRadius,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  container: {
    backgroundColor: colourConst.white,
    marginHorizontal: timeslots.margin,
    marginTop: timeslots.margingTop,
    marginBottom: timeslots.marginBottom,
    borderRadius: timeslots.radius,
    flexDirection: 'row',
    paddingBottom: timeslots.padding,
    paddingTop: timeslots.padding,
    alignItems: 'center',
    gap: timeslots.gap,
  },
  animated: {
    backgroundColor: colourConst.white,
    marginHorizontal: timeslots.margin,
    borderBottomLeftRadius: timeslots.radius,
    borderBottomRightRadius: timeslots.radius,
  },
});

export default AgendaEvent;
