import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useSecureContext } from '../../context';
import getColoursAndLocations from './utils';
import { childrenNodeProps } from '../../utils/types';
import { checkGoldenStatus } from '../../utils';
const RainbowLine = (props: childrenNodeProps) => {
  const { locationAndWave } = useSecureContext();

  const isGolden = checkGoldenStatus(locationAndWave?.wave?.ProjectRoles);
  const { colours, locations } = getColoursAndLocations(isGolden);
  return (
    <View style={style.container}>
      <LinearGradient
        colors={colours}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        locations={locations}
        style={style.line}
      />
      {props.children}
      <LinearGradient
        colors={colours}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        locations={locations}
        style={style.line}
      />
    </View>
  );
};
const style = StyleSheet.create({
  line: {
    height: 3,
  },
  container: {
    flex: 1,
  },
});

export default RainbowLine;
