import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const TextCardInfo = (props: TextProps) => {
  if (!props.children) {
    return null;
  }

  let content = props.children;
  let infoString = '';
  const jsonStartIndex = content.indexOf('{');
  let location = '';
  if (jsonStartIndex !== -1) {
    location = content
      .substring(0, jsonStartIndex)
      .replace(/^[\s-]+|[\s-]+$/g, '');
    try {
      content = JSON.parse(content.substring(jsonStartIndex));
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  if (content && typeof content === 'object') {
    const keysOrder = ['Table', 'Starter', 'Main'];
    const orderedContent = [];

    keysOrder.forEach((keyOrder) => {
      Object.keys(content).forEach((key) => {
        if (key.includes(keyOrder)) {
          orderedContent.push(`${keyOrder}: ${content[key]}`);
        }
      });
    });

    infoString = orderedContent.join(', ');
  } else {
    infoString = content;
  }

  return (
    <Text style={styles.text}>
      <ReactMarkdown>
        {location ? `${location}, ${infoString}` : infoString}
      </ReactMarkdown>
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.medium,
    color: colourConst.font,
    textAlign: 'left',
    lineHeight: 17,
    marginLeft: 21,
    marginRight: 10,
    marginBottom: 10,
  },
});
export default TextCardInfo;
